import { useApolloClient } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useReducer, useRef, useState } from "react";
import toast from "react-hot-toast";
import { create } from "react-modal-promise";
import CommonModal from "../components/CommonModal";
import CustomAutocomplete from "../components/CustomAutocomplete";
import CustomInput from "../components/CustomInput";
import CustomSelect from "../components/CustomSelect";
import { msg } from "../messages";

import {
  DEVICES_LINKED_QUERY,
  RPCS_QUERY,
  RPC_PARAMS_QUERY,
  UPDATE_WIDGET_PROPS_MUTATION,
  WIDGET_QUERY,
} from "../queries";

const EditWidgetActionModal = (props) => {
  const client = useApolloClient();
  const schemaId = useRef(null); // schemaId for selected device. Intermediate storage to pass it to RPC_PARAMS_QUERY. useRef to avoid re-render
  const [listOfDevices, setListOfDevices] = useState([]);
  const [listOfRpcs, setListOfRpcs] = useState([]);
  const [listOfParams, setListOfParams] = useState([]);

  const [buttonSaveDisabled, setButtonSaveDisabled] = useState(false);
  const [buttonControlDisabled, setButtonControlDisabled] = useState(true);

  let defaultValues = {};

  // for forms

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );
  /*
    const [valuesParams, setValuesParams] = useReducer(
      (prev, updated) => ({ ...prev, ...updated }),
      defaultValues
    );
  */

  const [valuesParams, setValuesParams] = useReducer(
    (prev, updated) =>
      updated["action"] === "reset" ? defaultValues : { ...prev, ...updated },
    defaultValues
  );

  useEffect(() => {
    if (values["device"] && !values["rpc"]) setButtonSaveDisabled(true);
    else setButtonSaveDisabled(false);

    if (!values["device"] || listOfRpcs.length === 0)
      setButtonControlDisabled(true);
    else setButtonControlDisabled(false);
  }, [values, listOfRpcs]);

  const loadRpcs = async (deviceId) => {
    try {
      const result = await client.query({
        query: RPCS_QUERY,
        variables: { deviceId: deviceId },
        fetchPolicy: "network-only",
      });

      return result.data.object.schema.schemaControls;
    } catch (err) {
      toast.error(err.toString());
    } finally {
    }
  };

  // get rpcs of device on device selection
  const handleDeviceChange = async (e) => {
    // schemaId

    // reset lists
    setListOfParams([]);
    setListOfRpcs([]);
    setValuesParams({ ["action"]: "reset" });

    if (!e.target.value) {
      return false;
    }

    try {
      const rpcs = await loadRpcs(e.target.value);

      if (rpcs.length === 0) {
        //setButtonSaveDisabled(true)
        return false;
      } else {
        //setButtonSaveDisabled(false)
      }

      schemaId.current = rpcs[0].schemaId;

      setListOfRpcs(
        rpcs.map((item) => {
          return { value: item.rpc, title: item.description };
        })
      );

      schemaId.current = rpcs[0].schemaId;
    } catch (e) {}
  }; //handleDeviceChange

  const loadRpcParams = async (rpc) => {
    try {
      const result = await client.query({
        query: RPC_PARAMS_QUERY,
        variables: { schemaId: schemaId.current, rpc: rpc },
        fetchPolicy: "network-only",
      });

      return result.data.schemaControls;
    } catch (err) {
      toast.error(err.toString());
    }
  };

  // get params of rpc on rpc selection
  const handleRpcChange = async (e) => {
    // reset list
    setListOfParams([]);
    setValuesParams({ ["action"]: "reset" });

    const rpcParams = await loadRpcParams(e.target.value);

    setListOfParams(
      rpcParams.map((item) => {
        return { value: item.argument, title: item.argument };
      })
    );
  }; //handleRpcChange

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: DEVICES_LINKED_QUERY,
          variables: { widgetId: props.widgetId },
          fetchPolicy: "network-only",
        });

        const result_widget = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId }, //, groupName: 'Value'
          fetchPolicy: "network-only",
        });

        const action = result_widget.data.object.objectProperties.find(
          (obj) => obj.key === "valueAction"
        )?.value;
        setListOfDevices(
          result.data.objects.map((item) => {
            return { value: item.id, title: item.name };
          })
        );

        if (!action) return false;

        // default values to fill input fields
        defaultValues["device"] = action.objectId;

        const rpcs = await loadRpcs(action.objectId);
        schemaId.current = rpcs[0].schemaId;
        setListOfRpcs(
          rpcs.map((item) => {
            return { value: item.rpc, title: item.description };
          })
        );

        defaultValues["rpc"] = action.rpc;
        setValues(defaultValues); // loaded values for each field

        const rpcParams = await loadRpcParams(action.rpc);

        setListOfParams(
          rpcParams.map((item) => {
            return { value: item.argument, title: item.argument };
          })
        );

        defaultValues = Object.keys(action.params).map((item) => {
          return { title: item, value: action.params[item] };
        });

        Object.keys(action.params).map((item) => {
          setValuesParams({ [item]: action.params[item] });
        });
        //setValuesParams({ [name]: value });
        //setValuesParams({["action"]:"reset"});
      } catch (err) {
        toast.error(err.toString());
      } finally {
      }
    }; //queryProps

    queryProps();
  }, []);

  const handleUpdate = async () => {
    const json = {
      objectId: values["device"],
      rpc: values["rpc"],
      params: valuesParams,
    };

    try {
      const result_update = await client.mutate({
        mutation: UPDATE_WIDGET_PROPS_MUTATION,
        variables: {
          widgetId: props.widgetId,
          values: [
            {
              propertyKey: "valueAction",
              value: values["device"] ? json : null,
            },
          ],
        },
      });

      toast.success(msg.editWidgetModal.updated);
      submit();
    } catch (err) {
      toast.error(err.toString());
    } //catch
  }; //handleUpdate

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (name === "device") handleDeviceChange(e);
    if (name === "rpc") handleRpcChange(e);
    if (checked) value = checked;

    setValues({ [name]: value });
  };

  const handleInputParamsChange = (e) => {
    let { name, value } = e.target;
    setValuesParams({ [name]: value });
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetActionModal.editAction}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetActionModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={handleUpdate}
              disabled={buttonSaveDisabled}
            >
              {msg.editWidgetActionModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomAutocomplete
              name="device"
              label={msg.editWidgetSourceModal.source}
              list={listOfDevices}
              value={values["device"] ?? ""}
              onChange={handleInputChange}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <CustomSelect
              name="rpc"
              label={msg.editWidgetActionModal.control}
              list={listOfRpcs}
              autoKey={true}
              value={values["rpc"] ?? ""}
              disabled={buttonControlDisabled}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            {listOfParams.map((item) => (
              <CustomInput
                key={item.value}
                name={item.value}
                label={item.title}
                clearFieldIcon={true}
                value={valuesParams[item.value] ?? ""}
                onChange={handleInputParamsChange}
              />
            ))}
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetActionModal);
