import { gql, useMutation } from "@apollo/client";
import { AvTimer } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EventIcon from "@mui/icons-material/Event";
import ExtensionIcon from "@mui/icons-material/Extension";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, ListItemSecondaryAction } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useCustomNavigate from "../../hooks/useCustomNavigate";
import useHandleCopy from "../menu/handlers/useHandleCopy";
import ParameterDateRange from "./modals/ParameterDateRange";
import ParameterObjectModal from "./modals/ParameterObjectModal";
import ParameterPeriod from "./modals/ParameterPeriod";
import StaticTableColumnsModal from "./modals/StaticTableColumnsModal";
import RpcSubscribeWrapper from "./RpcSubscribeWrapper";
import AccessSection from "../basic/AccessSection";
import ServiceSection from "../basic/ServiceSection";
import DescriptionSection from "../basic/DescriptionSection";

const CREATE_EXECUTION = gql`
  mutation createControlsExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      clientMutationId
      controlExecution {
        id
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  listSubheader: {
    marginTop: "15px",
    marginBottom: "10px",
  },
  itemToHideOrShow: {
    visibility: "hidden",
    opacity: 1,
    transition: "opacity 0.2s linear",
    [theme.breakpoints.down("sm")]: {
      visibility: "visible",
      opacity: 1,
    },
  },
  itemToHover: {
    "&:hover $itemToHideOrShow": {
      visibility: "visible",
      opacity: 1,
      transition: "opacity 0.2s linear",
    },
  },
}));

const StaticTableGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;
  const dispatch = useDispatch();
  const [createExecution, { loading }] = useMutation(CREATE_EXECUTION);
  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const classes = useStyles({
    listItem: {
      minHeight: "56px",
    },
  });

  const navigate = useCustomNavigate();

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce((acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }), {});

  const groups = grouping(item.objectProperties, "groupName");
  const groupNames = Object.keys(groups);

  const [fakeLoader, setFakeLoader] = useState(false);

  const columns = () => {
    const prop = item.objectProperties.find((item) => item.key === "settingsColumns").value.columns;

    if (prop.length) {
      return prop.map((item) => ({ ...item?.value, mode: item.mode }));
    }

    return [];
  };

  const modes = () => {
    const prop = item.objectProperties.find((item) => item.key === "settingsColumns").value.columns;

    if (prop.length) {
      return prop.map((item) => item?.mode);
    }

    return [];
  };

  const settingsColumns = () => {
    return item.objectProperties.find((item) => item.key === "settingsColumns");
  };

  const settingsObject = () => {
    return item.objectProperties.find((item) => item.key === "settingsObject");
  };

  const settingsPeriod = () => {
    return item.objectProperties.find((item) => item.key === "settingsPeriod");
  };

  const settingsTimezone = () => {
    return item.objectProperties.find((item) => item.key === "settingsTimezone");
  };

  const settingsDateRange = () => {
    return item.objectProperties.find((item) => item.key === "settingsDateRange");
  };

  const [parameterObject, setParameterObject] = useState(settingsObject().value);
  const [parameterDateRange, setParameterDateRange] = useState(settingsDateRange().value);
  const [parameterPeriods, setParameterPeriods] = useState(settingsPeriod().value);

  const [isMayGetData, setIsMayGetData] = useState(false);

  const handleCopy = useHandleCopy();

  useEffect(() => {
    if (!settingsObject().value.value || !settingsDateRange().value.value || !settingsColumns().value.columns.length) {
      setIsMayGetData(false);
    } else {
      setIsMayGetData(true);
    }
  }, [item]);

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = item.objectProperties.find((item) => item.key === "settingsColumns");

    const valueProperty = item.objectProperties.find((item) => item.key === "valueValue");
    const oldValues = cloneDeep(conditionPropertyItem.value.columns.map((item) => item));

    oldValues.splice(conditionIndex, 1);

    let patch = {
      columns: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {
      if (patch.columns.length === 0) {
        return updateProperty({
          variables: {
            input: {
              id: valueProperty.id,
              patch: {
                value: [],
              },
            },
          },
        });
      }
    });
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: "GetDataStaticTable",
            objectId: item.id,
            params: {
              object: parameterObject.value,
              period: parameterPeriods.value,
              range: parameterDateRange.value,
            },
          },
        },
      },
    });
  };

  return (
    <>
      <List>
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          button
          style={{ height: "48px", paddingRight: "96px" }}
          onClick={() => {}}
        >
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Object: {settingsObject().value.title}</Typography>}
            onClick={(e) => {
              navigate(settingsObject().value.value);
            }}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterObjectModal({
                  selected: settingsObject().value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsObject().id,
                          patch: {
                            value: object || { title: "n/a", value: null },
                          },
                        },
                      },
                    }).then(() =>
                      updateProperty({
                        variables: {
                          input: {
                            id: settingsColumns().id,
                            patch: {
                              value: {
                                columns: [],
                              },
                            },
                          },
                        },
                      })
                    );
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Date range: {settingsDateRange().value.title}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterDateRange({
                  selected: settingsDateRange(),
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsDateRange().id,
                          patch: {
                            value: date,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          classes={{
            container: classes.itemToHover,
          }}
          style={{ height: "48px", paddingRight: "96px" }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <AvTimer></AvTimer>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Period: {settingsPeriod().value.title}</Typography>}
            onClick={(e) => {}}
          />
          <ListItemSecondaryAction className={classes.itemToHideOrShow}>
            <IconButton
              size="small"
              onClick={() => {
                ParameterPeriod({
                  selected: settingsPeriod(),
                  save: (period) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: settingsPeriod().id,
                          patch: {
                            value: period,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {/*<ListItem*/}
        {/*  classes={{*/}
        {/*    container: classes.itemToHover,*/}
        {/*  }}*/}
        {/*  style={{ height: "48px", paddingRight: "96px" }}*/}
        {/*  button*/}
        {/*  onClick={() => {}}*/}
        {/*>*/}
        {/*  <ListItemIcon>*/}
        {/*    <MapClock />*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText*/}
        {/*    primary={*/}
        {/*      <Typography variant="body1">*/}
        {/*        {settingsTimezone()?.value.title}*/}
        {/*      </Typography>*/}
        {/*    }*/}
        {/*    onClick={(e) => {}}*/}
        {/*  />*/}
        {/*  <ListItemSecondaryAction className={classes.itemToHideOrShow}>*/}
        {/*    <IconButton*/}
        {/*      size="small"*/}
        {/*      onClick={() => {*/}
        {/*        ParameterTimeZone({*/}
        {/*          selected: settingsTimezone(),*/}
        {/*          save: (timezone) => {*/}
        {/*            updateProperty({*/}
        {/*              variables: {*/}
        {/*                input: {*/}
        {/*                  id: settingsTimezone().id,*/}
        {/*                  patch: {*/}
        {/*                    value: timezone,*/}
        {/*                  },*/}
        {/*                },*/}
        {/*              },*/}
        {/*            }).then(() => {});*/}
        {/*          },*/}
        {/*        })*/}
        {/*          .then()*/}
        {/*          .catch(() => {});*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <EditIcon />*/}
        {/*    </IconButton>*/}
        {/*  </ListItemSecondaryAction>*/}
        {/*</ListItem>*/}
        <ListSubheader color="primary" className={classes.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {columns().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: classes.itemToHover,
              }}
              style={{ height: "48px", paddingRight: "96px" }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={<Typography variant="body1">{`${item.title} (${item.mode})`}</Typography>}
                onClick={(e) => {}}
              />
              <ListItemSecondaryAction className={classes.itemToHideOrShow}>
                <IconButton
                  size="small"
                  onClick={() => {
                    StaticTableColumnsModal({
                      parameterObject: settingsObject().value,
                      columns: settingsColumns(),
                      isEdit: true,
                      condition: columns()[index],
                      mode: modes()[index],
                      conditionIndex: index,
                    })
                      .then()
                      .catch(() => {});
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: "48px" }}
          button
          onClick={() => {
            StaticTableColumnsModal({
              parameterObject: settingsObject().value,
              columns: settingsColumns(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add column</Typography>} />
        </ListItem>

        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={"GetDataStaticTable"}
            objectId={item.id}
            disabled={!isMayGetData}
            object={item}
            title={"Update table"}
            handler={rpcHandler}
          >
            <LoadingButton fullWidth={true} variant={"outlined"}></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={classes} item={item} />

        <AccessSection classes={classes} item={item} />

        {item.description && <DescriptionSection classes={classes} item={item} />}
      </List>
    </>
  );
};

export default StaticTableGeneral;
