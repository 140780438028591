import { gql, useLazyQuery } from "@apollo/client";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useReducer, useState } from "react";
import { create } from "react-modal-promise";
import CommonModal from "../../../../components/CommonModal";
import { msg } from "../../../../messages";
import CustomSelect from "../../../CustomSelect";
import ObjectsAutocomplete from "../../../forms/ObjectsAutocomplete";
import SchemasAutocomplete from "../../../forms/SchemasAutocomplete";

const GET_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      id
      schemaTags
      schemaType
      schemaId
    }
  }
`;

const ParameterObjectModal = (props) => {
  let defaultValues = {};

  const [getObjectQuery] = useLazyQuery(GET_OBJECT, {
    variables: {
      objectId: props?.selected?.value,
    },
    fetchPolicy: "network-only",
  });

  const [type, setType] = useState("device");
  const [objectId, setObjectId] = useState(null);
  const [schemaId, setSchemaId] = useState("");

  const objectTypes = [
    { value: "device", title: "Device" },
    { value: "dataset", title: "Dataset" },
    { value: "monitor", title: "Monitor objects" },
    { value: "stat", title: "Statistic objects" },
    { value: "application", title: "Application" },
  ];

  const [values, setValues] = useReducer(
    (prev, updated) => ({ ...prev, ...updated }),
    defaultValues
  );

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    if (name === "object") {
      setValues({ [name]: e.target.rawValue });
    } else {
      setValues({ [name]: value });
    }
  };

  const handleChangeType = (e) => {
    setType(e.target.value);
  };

  const handleLinkWidget = () => {
    props.save(values["object"]);
    submit();
  };

  useEffect(() => {
    if (props.selected) {
      getObjectQuery().then(({ data }) => {
        const object = data.object;

        if (
          object.schemaTags.includes("application") &&
          object.schemaTags.includes("monitor")
        ) {
          setType("monitor");
        } else if (
          object.schemaTags.includes("application") &&
          object.schemaTags.includes("statistics")
        ) {
          setType("stat");
        } else {
          setType(object.schemaType);
          setObjectId(object.id);
        }

        setSchemaId(object.schemaId);
      });
    }
  }, [props.selected]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={"Select object"}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleLinkWidget}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomSelect
              name="object-type"
              label="Schema type"
              value={type}
              list={objectTypes}
              onChange={handleChangeType}
            />
          </Grid>
          <Grid item>
            <SchemasAutocomplete
              schemaId={schemaId}
              type={type}
              name="schemaId"
              handleChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <ObjectsAutocomplete
              schemaId={values["schemaId"]}
              name="object"
              objectId={objectId}
              handleChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterObjectModal);
